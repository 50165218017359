/*
 * NOTE: All of these variables are imported into javascript.
 * If you change ANY variable names you must propagate those changes into the js.
 */

/*
 * NOTE: No comments allowed on the same line as any variables.
 * Careful, comments may be parsed into js and could break _colors.js
 */

// Atlas Colors
$black: #000;
$white: #fff;

$very-dark-desaturated-blue: #1f263e;
$very-dark-blue: #294768;
$dark-moderate-blue: #30537a;
$light-grayish-blue1: #bfcadc;
$light-grayish-blue2: #dfe1e8;
$light-grayish-blue3: #f3f5f7;
$very-pale-blue: #faf9ff;
$grayish-blue: #9fafba;

$dark-moderate-lime-green: #2c8d49;
$dark-green: #61a00c;
// End Atlas Colors

// Blueprintjs colors

$light-bluegray1: #ced9e0;
$light-bluegray2: #d8e1e8;
$light-bluegray3: #e1e8ed;
$light-bluegray4: #ebf1f5;
$light-bluegray5: #f5f8fa;

$dark-gray1: #262626;
$dark-gray2: #333;
$dark-gray3: #424242;
$dark-gray4: #4d4d4d;
$dark-gray5: #595959;
$dark-gray6: #777;

$gray1: #808080;
$gray2: #949494;
$gray3: #a8a8a8;
$gray4: #c2c2c2;
$gray5: #d6d6d6;

$light-gray1: #e0e0e0;
$light-gray2: #e8e8e8;
$light-gray3: #ededed;
$light-gray4: #f5f5f5;
$light-gray5: #fafafa;

$blue1: #0e5a8a;
$blue2: #106ba3;
$blue3: #137cbd;
$blue4: #2b95d6;
$blue5: #48aff0;

$green1: #0a6640;
$green2: #0d8050;
$green3: #0f9960;
$green4: #15b371;
$green5: #3dcc91;

$orange1: #a66321;
$orange2: #bf7326;
$orange3: #d9822b;
$orange4: #f29d49;
$orange5: #ffb366;

$red1: #a82a2a;
$red2: #c23030;
$red3: #db3737;
$red4: #f55656;
$red5: #ff7373;
$red8: #f8dbde;

$vermilion1: #9e2b0e;
$vermilion2: #b83211;
$vermilion3: #d13913;
$vermilion4: #eb532d;
$vermilion5: #ff6e4a;

$rose1: #a82255;
$rose2: #c22762;
$rose3: #db2c6f;
$rose4: #f5498b;
$rose5: #ff66a1;

$violet1: #5c255c;
$violet2: #752f75;
$violet3: #8f398f;
$violet4: #a854a8;
$violet5: #c274c2;

$indigo1: #5642a6;
$indigo2: #634dbf;
$indigo3: #7157d9;
$indigo4: #9179f2;
$indigo5: #ad99ff;

$cobalt1: #1f4b99;
$cobalt2: #2458b3;
$cobalt3: #2965cc;
$cobalt4: #4580e6;
$cobalt5: #669eff;

$turquoise1: #008075;
$turquoise2: #00998c;
$turquoise3: #00b3a4;
$turquoise4: #14ccbd;
$turquoise5: #2ee6d6;

$forest1: #1d7324;
$forest2: #238c2c;
$forest3: #29a634;
$forest4: #43bf4d;
$forest5: #62d96b;

$lime1: #728c23;
$lime2: #87a629;
$lime3: #9bbf30;
$lime4: #b6d94c;
$lime5: #d1f26d;

$gold1: #a67908;
$gold2: #bf8c0a;
$gold3: #d99e0b;
$gold4: #f2b824;
$gold5: #ffc940;

$sepia1: #63411e;
$sepia2: #7d5125;
$sepia3: #96622d;
$sepia4: #b07b46;
$sepia5: #c99765;

$pt-intent-primary: $blue3;
$pt-intent-success: $green3;
$pt-intent-warning: $orange3;
$pt-intent-danger: $red3;

$pt-intent-success-disabled: #6bb395;
$pt-intent-warning-disabled: #d9ad82;
$pt-intent-danger-disabled: $red5;

$pt-app-background-color: $light-gray5;
$pt-dark-app-background-color: $dark-gray3;
