@import '../../styles/_colors.scss';

.Navigation {
  .Navigation__Appbar {
    background-color: $white;
    color: $black;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2);

    .Navigation__Appbar__Toolbar {
      display: flex;
      justify-content: space-between;

      .Navigation__Appbar__Logout {
        color: $dark-moderate-blue;
        position: fixed;
        right: 20px;

        &:hover {
          color: $dark-green;
        }
      }
    }

    &.Navigation__Appbar--fullWidth {
      width: 100%;

      + nav {
        width: 0;
      }
    }
  }
}

.Navigation__Drawer {
  .MuiPaper-root {
    background-color: $dark-moderate-blue;
    border: none;
    color: white;
    overflow: hidden;

    .Navigation__Drawer__Toolbar {
      background-color: $very-dark-blue;
      box-shadow: 0 0 8px 0 Rgb(0 0 0/ 50%);
      z-index: 0;
    }

    .Navigation__Drawer__Content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      background: linear-gradient(180deg, rgba(48, 83, 122, 1) 0%, rgba(34, 63, 96, 1) 100%);

      .Navigation__Drawer__Content__List {
        padding: 0;

        .MuiListItem-root {
          color: white;
          border-bottom: 1px solid rgba(159, 175, 186, 0.25);

          &.selected {
            background-image: linear-gradient(to right, $dark-moderate-lime-green, #4fa7a7);

            border-right: 5px solid $white;
          }

          .Navigation__Drawer__Icon {
            color: white;
          }

          .MuiListItemText-root {
            .MuiTypography-root {
              font-family: 'Open Sans';
              font-weight: 300;
            }
          }
        }
        .MuiCollapse-container {
          .MuiListItem-root {
            padding-left: 30%;
          }
        }
      }

      .Navigation__Drawer__Content__Footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        margin-bottom: 15px;

        p {
          font-size: 12px;

          span {
            color: white;
            text-decoration: underline;
            font-weight: 600;
          }
        }
      }
    }
  }
}

main {
  background-color: $very-pale-blue;

  &.main--fullWidth {
    width: 100%;
  }

  .Footer {
    height: 150px;

    .Footer__Content {
      background-color: $very-dark-blue;
      height: 90px;
      padding: 30px;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      //border-bottom: 1px solid #9fafba;
      border-bottom: 1px solid rgba(159, 175, 186, 0.25);

      .Footer__Content__Copyright {
        color: $white;
        font-size: 12px;
      }
    }

    .Footer__Privacy {
      height: 60px;
      background-color: #162d48;
      padding: 20px 30px;

      a {
        color: $white;
        font-size: 16px;
        font-weight: 600;

        &:last-child {
          padding-left: 40px;
        }
      }
    }
  }
}
