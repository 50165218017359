.Page {
  ::-webkit-scrollbar {
    width: 0.5px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #dadce0;
    border-radius: 8px;
  }
}
