@import './_base.scss';

// Apply a natural box layout model to all elements, but allowing components to change
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// TODO: Re-evaluate this for accessibility purposes
*:focus {
  outline: none;
}

body {
  font-size: $font-size-default;
  font-family: $font-family-default;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
    cursor: pointer;
  }
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: $font-family-heading;
// }

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
  font-weight: $font-weight-semibold;
}

h3 {
  font-size: $font-size-h3;
  font-weight: $font-weight-normal;
}

h4 {
  font-size: $font-size-h4;
  font-weight: $font-weight-semibold;
}
