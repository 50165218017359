.ActiveUsersPlatformCard {
  .ActiveUsersPlatformCard__Column {
    padding: 0 !important;

    &:first-of-type {
      .ActiveUsersPlatformCard__item {
        &:first-of-type {
          border: 2px solid #ebf0f4;
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
          border-left: 2px solid #ebf0f4;
          border-bottom: 2px solid #ebf0f4;
          border-right: 2px solid #ebf0f4;
        }
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      .ActiveUsersPlatformCard__item {
        &:first-of-type {
          border-top: 2px solid #ebf0f4;
          border-bottom: 2px solid #ebf0f4;
          border-right: 2px solid #ebf0f4;
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
          border-bottom: 2px solid #ebf0f4;
          border-right: 2px solid #ebf0f4;
        }
      }
    }

    .ActiveUsersPlatformCard__item {
      max-height: 83px;
      padding: 16px !important;

      h3 {
        color: $very-dark-desaturated-blue;
        font-size: 16px;
      }

      .ActiveUsersPlatformCard__item__value {
        color: $very-dark-desaturated-blue;
        font-size: 32px;
        font-weight: 600;
        display: block;
        padding-top: 8px;
      }
    }
  }

  .ActiveUsersPlatformCard__Column--pie {
    padding: 0;
    margin-left: 20px;
    border: 2px solid #ebf0f4;
    background-color: #f9fafd;
  }
}
