@import '../../../styles/_colors.scss';

.PageHeader {
  display: flex;
  border-bottom: 1px solid $light-grayish-blue2;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin: 0px 16px 10px;
  }
}
