.ProgressBar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .MuiLinearProgress-root {
    width: 75%;
    border-radius: 3px;
    background-color: #d3d4d9;
  }
}
