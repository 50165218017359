@import '../../styles/_colors.scss';

.StatisticCard {
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2) !important;
  border-radius: 10px !important;

  .StatisticCard__Content {
    flex: 1;
    color: $very-dark-desaturated-blue;

    .StatisticCard__Content__Title {
      color: $very-dark-desaturated-blue;
      font-weight: 500;
      letter-spacing: 1px;
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 600;
    }

    .StatisticCard__Content__Date {
      float: right;
    }

    .StatisticCard__Content__Info {
      color: $light-grayish-blue1;
      vertical-align: middle;
      float: right;
    }

    hr {
      border: 0.5px solid $light-grayish-blue2;
      margin: 10px 40px 18px 0;
    }

    .StatisticCard__Content__Stat {
      font-family: 'Open Sans';
      font-weight: 600;
    }

    .StatisticCard__Content__Stat--currency {
      color: $dark-green;
    }
  }
}
