.ActiveUsersCountryTable {
  .MuiPaper-root {
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2) !important;

    @media (max-width: 400px) {
      max-width: 275px;
    }

    .MuiToolbar-root {
      padding: 20px 20px 10px;
      min-height: 32px;

      .MuiTypography-root {
        color: $very-dark-desaturated-blue;
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1px;
      }

      .MuiTextField-root {
        background-color: $light-grayish-blue3;
        border-radius: 15px;
        margin-right: 20px;

        .MuiInput-underline::before,
        .MuiInput-underline::after {
          border: none;
        }
      }
    }

    .MuiTable-root {
      .MuiTableCell-root {
        border: none;
      }

      .MuiTableHead-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            color: $very-dark-desaturated-blue;
            font-family: 'Open Sans';
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableRow-root {
          color: $very-dark-desaturated-blue;
          font-family: 'Open Sans';
          font-size: 14px;
          font-weight: 400;
          height: auto!important;
        }

        .MuiTableRow-root:nth-child(odd) {
          background-color: $light-grayish-blue3;
        }
      }
    }
  }
}
