// Base stylesheet to include in all components.
// Contains variables & mixins only.
// Put any global app styles in App.scss

$font-family-default: 'Open Sans', sans-serif;
$font-family-heading: 'Open Sans', sans-serif;

$font-size-h1: 2em;
$font-size-h2: 1.5em;
$font-size-h3: 1.17em;
$font-size-h4: 1em;
$font-size-default: 16px;
$font-size-small: 14px;
$font-size-x-small: 12px;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400; // normal
$font-weight-normal: 400; // normal
$font-weight-medium: 500;
$font-weight-semibold: 600; // bold, in most cases
$font-weight-bold: 700;

$sidenav-width: 290px;
$nav-height: 64px; //64px

// Breakpoints
$dimension-phone: 500px;
$dimension-tablet-small: 768px;
$dimension-tablet-large: 910px;
$dimension-desktop-small: 1025px;
$dimension-desktop-medium: 1201px;
$dimension-desktop-large: 1601px;
$dimension-desktop-x-large: 1921px;

$phone: '(min-width: #{$dimension-phone})';
$tablet-small: '(min-width: #{$dimension-tablet-small}) ';
$tablet-large: '(min-width: #{$dimension-tablet-large}) ';
$desktop-small: '(min-width: #{$dimension-desktop-small}) ';
$desktop-medium: '(min-width: #{$dimension-desktop-medium}) ';
$desktop-large: '(min-width: #{$dimension-desktop-large}) ';
$desktop-x-large: '(min-width: #{$dimension-desktop-x-large}) ';

$transition-sidenav-open: 0.25s ease-in-out;

$modal-min-height: 180px;

$page-padding-horizontal: 20px;
$page-padding-vertical: 20px;
