@import '../../styles/_colors.scss';

.Card {
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2) !important;
  border-radius: 10px !important;

  .Card__Content {
    flex: 1;

    .Card__Content__Title {
      display: flex;
      align-items: center;

      .MuiTypography-root {
        color: $very-dark-desaturated-blue;
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1px;
      }

      .Card__Content__Title--right {
        display: flex;
        align-items: center;
        margin-left: auto;
        order: 2;

        .SwitchField {
          padding-right: 10px;

          .MuiFormControlLabel-label {
            color: #676767;
          }

          .MuiIconButton-root {
            color: $very-dark-blue;
          }
        }

        .Card__Content__Title__Info {
          color: $light-grayish-blue1;
        }
      }
    }

    hr {
      border: 0.5px solid $light-grayish-blue2;
      margin: 0px 40px 18px 0;
    }
  }
}
